import { createStore } from 'effector';
import Slide_1 from '../assets/SlidesMpk/1.png';
import Slide_2 from '../assets/SlidesMpk/2.png';
import Slide_3 from '../assets/SlidesMpk/3.png';
import Slide_4 from '../assets/SlidesMpk/4.png';
import Slide_5 from '../assets/SlidesMpk/5.png';
import Slide_6 from '../assets/SlidesMpk/6.png';
import Slide_7 from '../assets/SlidesMpk/7.png';


const MpkSlidesSrc = [
  {
    image: Slide_1,
    title: 'Электронный паспорт объекта.',
  },
  {
    image: Slide_2,
    title: 'Планировщик проверок.',
  },
  {
    image: Slide_3,
    title: 'Электронные чек-листы с матрицей применимости к типу объекта по областям контроля.',
  },
  {
    image: Slide_4,
    title: 'Фиксация нарушений на объекте контроля.',
  },
  {
    image: Slide_5,
    title: 'Назначение мероприятий по устранению нарушений и контроль их исполнения.',
  },
  {
    image: Slide_6,
    title: 'Визуализация состояния пром. безопасности.',
  },
  {
    image: Slide_7,
    title: 'Дашборд состояния объекта.',
  },
];

export const mpkSlidesData = createStore(MpkSlidesSrc);
