import { useState, useEffect } from 'react';
import './App.css';
import EducationBlock from './components/EducationBlock/EducationBlock';
import Footer from './components/Footer/Footer';
import HeaderBanner from './components/HeaderBanner/HeaderBanner';
import HomeUp from './components/HomeUp/HomeUp';
import MPK from './components/MPK/MPK';
import OurTeam from './components/OurTeam/OurTeam';
import PassportBlock from './components/PassportBlock/PassportBlock';
import Timeline from './components/Timeline/Timeline';
import Sidebar from './components/Sidebar/Sidebar';
import AlertMobile from './ui/AlertMobile/AlertMobile';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import StatisticBlock from './components/StatisticBlock/StatisticBlock';
import StatisticMap from './components/StatisticMap/StatisticMap';

function App() {
  const [showAlert, setShowAlert] = useState(false);

  const handleButtonClick = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  return (
    <div className="App">
      <HeaderBanner />
      <Timeline />
      <OurTeam />
      <StatisticBlock />
      <StatisticMap />
      <EducationBlock />
      <PassportBlock />
      <MPK onButtonClick={handleButtonClick} />
      <HomeUp />
      <Footer />
      <AlertMobile
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      <Sidebar />
      <LoadingScreen />
    </div>
  );
}

export default App;
