import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './OurTeam.module.css';
//* Стор Effector
import { useStore } from 'effector-react';
import { ourTeamSrcData } from '../../store/OurTeam';

//* Адаптив карусельки
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1250, min: 650 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 650, min: 364 },
    items: 1,
    slidesToSlide: 1,
  },
};

const OurTeam = () => {
  const team = useStore(ourTeamSrcData);
  return (
    <div
      className={styles.root}
      id="reviews">
      <div className={styles.title}>Наша команда</div>
      <div className={styles.carusel}>
        <Carousel
          responsive={responsive}
          autoPlaySpeed={4000}
          autoPlay={true}
          arrows={true}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          partialVisible={false}>
          {team.map((current, index) => {
            return (
              <div
                className={styles.slider}
                key={index}>
                <img
                  className={styles.face_pic}
                  src={current.src}
                  alt="person"
                />
                <div className={styles.name}> {current.name}</div>
                <div className={styles.post}> {current.post}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
export default OurTeam;
