import { createStore } from 'effector';
import Slide_1 from '../assets/SlidesEducation/1.png';
import Slide_2 from '../assets/SlidesEducation/2.png';
import Slide_3 from '../assets/SlidesEducation/3.jpg';
import Slide_4 from '../assets/SlidesEducation/4.png';
import Slide_5 from '../assets/SlidesEducation/5.jpg';
import Slide_6 from '../assets/SlidesEducation/6.jpg';
import Slide_7 from '../assets/SlidesEducation/7.jpg';
import Slide_8 from '../assets/SlidesEducation/8.jpg';
import Slide_9 from '../assets/SlidesEducation/9.jpg';
import Slide_10 from '../assets/SlidesEducation/10.jpg';
import Slide_11 from '../assets/SlidesEducation/11.jpg';

const educationSlidesSrc = [
  {
    image: Slide_1,
    title:
      '1. Поступает заявка на обучение по заранее согласованному с заказчиком графику',
    description:
      'В заявке могут быть курсы, разработанные ЮУЦ или созданные специально под запрос заказчика опытными методистами.',
  },
  {
    image: Slide_2,
    title: '2. Прием заявок на обучение от кураторов организаций',
    description:
      'Специалисты учебного центра принимают в работу электронную заявку на обучение по программам дополнительного профессионального образования и профессионального обучения (в том числе для сдачи в системе ЕИСОТ) и персонифицированным курсам от заказчика.',
  },
  {
    image: Slide_3,
    title: '3. Проверка поступивших заявок ',
    description:
      'В течение нескольких минут автоматизированно загружается и обрабатывается заявка, система сверяет верность заполнения данных перед началом обучения.',
  },
  {
    image: Slide_4,
    title: '4. Загрузка поступивших заявок в СДО, формирование учебных групп ',
    description:
      'Заявки оперативно подгружаются в систему, четкую систематичность и точность которой обеспечивает своя команда IT-специалистов, регулярно оказывающих тех.поддержку пользователям.',
  },
  {
    image: Slide_5,
    title: '5. Обучение с помощью новых технологий ',
    description:
      'Обучение слушателей происходит с использованием интерактивных элементов, в том числе VR-систем и разработанного компанией мобильного инфокиоска.',
  },
  {
    image: Slide_6,
    title: '6. Непрерывный контроль образовательного процесса.',
    description:
      'Специалисты оперативно отвечают на любые вопросы слушателей и кураторов во время обучения, в том числе на вопросы по аттестации, функционалу СДО и получению документов после успешного завершения аттестации.',
  },
  {
    image: Slide_7,
    title: '7. Закрытие обучения, тестирование',
    description:
      'Успешное завершение обучения, рассылка кураторам предприятий, закрытие протоколов и внесение необходимых данных в федеральные реестры.',
  },
  {
    image: Slide_8,
    title: '8. Согласование итоговых документов ',
    description:
      'В регламентируемые сроки учебный центр согласовывает с кураторами корректность итоговых документов об обучении.',
  },
  {
    image: Slide_9,
    title: '9. Подготовка и подписание актов',
    description:
      'После согласования итоговых документов руководитель отдела обучения готовит акты выполненных работ по каждой организации и куратору.',
  },
  {
    image: Slide_10,
    title: '10. Отправка документов ',
    description:
      'Специалисты готовят протоколы, свидетельства, удостоверения и дипломы к отправке курьерской службой. Каждый пакет четко сгруппирован по организациям и кураторам, а трек-номер хранится до момента получения реципиентом посылки.',
  },
  {
    image: Slide_11,
    title: '11. Доставка документов ',
    description:
      'После отправки документов и накладных курьерской службой команда ожидает обратной связи от получателя.',
  },
];

export const educationSlidesSrcData = createStore(educationSlidesSrc);
