import React, { useState } from 'react';
import styles from './MPK.module.css';
//* Компоненты
import Map from '../Map/Map';
//* Иконки
import InsightsIcon from '@mui/icons-material/Insights';
//* Модалки
import ModalMpk from '../../ui/modal/ModalMpk';

const MPK = ({ onButtonClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  //* Хэндлеры для открытие и закрытие модалок
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className={styles.root}
      id="mpk">
      <div className={styles.title}>МПК</div>
      <div className={styles.mpk_wrapper}>
        <ModalMpk
          isOpen={isModalOpen}
          onClose={closeModal}
        />
        <div className={styles.block_wrapper}>
          <div className={styles.first_block}>
            <InsightsIcon className={styles.icon} />
            <div className={styles.title_card}>
              Модуль производственного контроля
            </div>
            Позволяет вести электронные паспорта объектов и оборудования,
            реализовывать планирование и проведение проверок согласно чек-листам
            и матрице применимости, назначать мероприятия и осуществлять
            контроль устранения нарушений.
          </div>
          <div
            className={styles.second_block}
            onClick={openModal}>
            Начать
            <br /> знакомство
          </div>
        </div>
      </div>
      <Map onButtonClick={onButtonClick} />
    </div>
  );
};

export default MPK;
