import React from 'react';
import LogoSimax from '../../assets/logo.png';
import styles from './Logo.module.css';

const Logo = () => {
  return (
    <div className={styles.root}>
      <img
        className={styles.logo}
        src={LogoSimax}
        alt="Logo"
      />
    </div>
  );
};

export default Logo;
