import React from 'react';
import styles from './LoadingScreen.module.css';
//* Графика
import S from '../../assets/SimaxLetter/s.png';
import I from '../../assets/SimaxLetter/i.png';
import M from '../../assets/SimaxLetter/m.png';
import A from '../../assets/SimaxLetter/a.png';
import X from '../../assets/SimaxLetter/x.png';

const LoadingScreen = () => {
  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img
          src={S}
          alt=""
          className={styles.letter}
          style={{ animationDelay: '0.3s' }}
        />
        <img
          src={I}
          alt=""
          className={styles.letter}
          style={{ animationDelay: '0.4s' }}
        />
        <img
          src={M}
          alt=""
          className={styles.letter}
          style={{ animationDelay: '0.5s' }}
        />
        <img
          src={A}
          alt=""
          className={styles.letter}
          style={{ animationDelay: '0.6s', margin:'0px 10px'}}
        />
        <img
          src={X}
          alt=""
          className={styles.letter}
          style={{ animationDelay: '0.7s' }}
        />
      </div>
    </div>
  );
};

export default LoadingScreen;
