import React, { useState } from 'react';
import styles from './Map.module.css';
import './alert.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
//* Графика
import ClearMap from '../../assets/clearMap.png';
import Sklad from '../../assets/sklad.png';
import SkladShadow from '../../assets/skladShadow.png';
import Factory from '../../assets/factory.png';
import FactoryShadow from '../../assets/factoryShadow.png';
import City from '../../assets/city.png';
import CityShadow from '../../assets/cityShadow.png';
import Info from '../../assets/infoNew.png';
import Dialog from '../../assets/dialog.png';
import CloseIcon from '@mui/icons-material/Close';
import RotateArrow from '../../assets/rotateArrow.png';
import RotatePhone from '../../assets/rotatePhone.png';

const Map = ({ onButtonClick }) => {
  const [isCityHovered, setIsCityHovered] = useState(false);
  const [isFactoryHovered, setIsFactoryHovered] = useState(false);
  const [isSkladHovered, setIsSkladHovered] = useState(false);
  const [isMapHovered, setIsMapHovered] = useState(false);

  const [isDialogCity, setIsDialogCity] = useState(false);
  const [isDialogFactory, setIsDialogFactory] = useState(false);
  const [isDialogSklad, setIsDialogSklad] = useState(false);

  //* Хэндлер для отображения тени и затемнения не активных элементов
  const handleCityMouseOver = () => {
    setIsFactoryHovered(true);
    setIsSkladHovered(true);
    setIsMapHovered(true);
  };

  const handleCityMouseOut = () => {
    setIsFactoryHovered(false);
    setIsSkladHovered(false);
    setIsMapHovered(false);
  };

  //* -----------------------------------------------------------------

  const handleFactoryMouseOver = () => {
    setIsCityHovered(true);
    setIsSkladHovered(true);
    setIsMapHovered(true);
  };

  const handleFactoryMouseOut = () => {
    setIsCityHovered(false);
    setIsSkladHovered(false);
    setIsMapHovered(false);
  };

  //* -----------------------------------------------------------------

  const handleSkladMouseOver = () => {
    setIsCityHovered(true);
    setIsFactoryHovered(true);
    setIsMapHovered(true);
  };

  const handleSkladMouseOut = () => {
    setIsCityHovered(false);
    setIsFactoryHovered(false);
    setIsMapHovered(false);
  };

  //* Хэндлер для открытия диалоговых окон при нажатии на объекты
  const toggleDialogCity = () => {
    setIsDialogCity(!isDialogCity);
  };
  const toggleDialogFactory = () => {
    setIsDialogFactory(!isDialogFactory);
  };
  const toggleDialogSklad = () => {
    setIsDialogSklad(!isDialogSklad);
  };

  return (
    <div className={styles.root}>
      <div className={styles.map}>
        <div
          className={`${styles.clear_map} ${
            isMapHovered ? styles.grayscale : ''
          }`}>
          <img
            src={ClearMap}
            alt="clear_map"
          />
          <div
            className={styles.rotate}
            onClick={onButtonClick}>
            <img
              className={styles.rotateArrow}
              src={RotateArrow}
              alt="RotateArrow"
            />
            <img
              className={styles.rotatePhone}
              src={RotatePhone}
              alt="RotatePhone"
            />
          </div>
        </div>
        <div
          className={`${styles.sklad} ${
            isSkladHovered ? styles.grayscale : ''
          }`}
          onMouseOver={handleSkladMouseOver}
          onMouseOut={handleSkladMouseOut}
          onClick={toggleDialogSklad}>
          <img
            src={Sklad}
            alt="sklad"
          />
        </div>
        <div
          className={`${styles.factory} ${
            isFactoryHovered ? styles.grayscale : ''
          }`}
          onMouseOver={handleFactoryMouseOver}
          onMouseOut={handleFactoryMouseOut}
          onClick={toggleDialogFactory}>
          <img
            src={Factory}
            alt="factory"
          />
        </div>
        <div
          className={`${styles.city} ${isCityHovered ? styles.grayscale : ''}`}
          onMouseOver={handleCityMouseOver}
          onMouseOut={handleCityMouseOut}
          onClick={toggleDialogCity}>
          <img
            src={City}
            alt="city"
          />
        </div>
        <div className={styles.skladShadow}>
          <img
            src={SkladShadow}
            alt="skladShadow"
          />
        </div>
        <div className={styles.factoryShadow}>
          <img
            src={FactoryShadow}
            alt="factoryShadow"
          />
        </div>
        <div className={styles.cityShadow}>
          <img
            src={CityShadow}
            alt="cityShadow"
          />
        </div>
        <div className={styles.infoFactory}>
          <img
            src={Info}
            alt="infoFactory"
          />
        </div>
        <div className={styles.infoSklad}>
          <img
            src={Info}
            alt="infoSklad"
          />
        </div>
        <div className={styles.infoCity}>
          <img
            src={Info}
            alt="infoCity"
          />
        </div>
        <TransitionGroup>
          {isDialogCity && (
            <CSSTransition
              classNames="dialog"
              timeout={300}>
              <div className={styles.dialogCity}>
                <CloseIcon
                  className={styles.close}
                  onClick={toggleDialogCity}
                />
                <div className={styles.title}>Объект: Мегаполис</div>
                <div className={styles.desc1}>Мероприятия:</div>
                <div className={styles.desc2}>Устранено 64 нарушения</div>
                <div className={styles.desc3}>из 67 выявленных</div>
                <img
                  src={Dialog}
                  alt="dialogCity"
                />
              </div>
            </CSSTransition>
          )}
          {isDialogFactory && (
            <CSSTransition
              classNames="dialog"
              timeout={300}>
              <div className={styles.dialogFactory}>
                <CloseIcon
                  className={styles.close}
                  onClick={toggleDialogFactory}
                />
                <div className={styles.title}>Объект: Фабрика</div>
                <div className={styles.desc1}>Проверки:</div>
                <div className={styles.desc2}>Запланированно - 123 </div>
                <div className={styles.desc3}>Проведено - 101</div>
                <img
                  src={Dialog}
                  alt="dialogFactory"
                />
              </div>
            </CSSTransition>
          )}
          {isDialogSklad && (
            <CSSTransition
              classNames="dialog"
              timeout={300}>
              <div className={styles.dialogySklad}>
                <CloseIcon
                  className={styles.close}
                  onClick={toggleDialogSklad}
                />
                <div className={styles.title}>Объект: Склад</div>
                <div className={styles.desc1}>Нарушения:</div>
                <div className={styles.desc2}>Охрана труда - 357</div>
                <div className={styles.desc3}>Устранено - 17</div>
                <img
                  src={Dialog}
                  alt="dialogySklad"
                />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Map;
