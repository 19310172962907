import { createStore } from 'effector';
import Slide_1 from '../assets/SlidesAdmin/1.png';
import Slide_2 from '../assets/SlidesAdmin/2.jpg';
import Slide_3 from '../assets/SlidesAdmin/3.png';
import Slide_4 from '../assets/SlidesAdmin/4.jpg';
import Slide_5 from '../assets/SlidesAdmin/5.jpg';
import Slide_6_1 from '../assets/SlidesAdmin/6_1.png';
import Slide_6_2 from '../assets/SlidesAdmin/6_2.png';
import Slide_7_1 from '../assets/SlidesAdmin/7_1.jpg';
import Slide_7_2 from '../assets/SlidesAdmin/7_2.jpg';
import Slide_8 from '../assets/SlidesAdmin/8.png';

const AdminSlidesSrc = [
  {
    image: Slide_1,
    title: '1. Поступление заявки',
    description:
      'В отдел обучения Югорского Учебного центра поступает и обрабатывается входящая заявка от куратора.',
  },
  {
    image: Slide_2,
    title: '2. Подбор необходимого курса',
    description:
      'Специалист отдела обучения находит необходимый курс из перечня созданных учебным центром, либо передаёт задачу методисту на создание персонального курса под конкретного заказчика.',
  },
  {
    image: Slide_3,
    title: '3. Загрузка заявки',
    description:
      'После проверки данных в поступившей заявке специалистом система автоматически загружает курсы, распределяя обучение по кураторам и организациям.',
  },
  {
    image: Slide_4,
    title: '4. Проверка обучения',
    description:
      'Во время прохождения группами обучения сотрудники учебного центра проверяют своевременность прохождения и результаты тестирования, а также отвечают на любые поступающие вопросы.',
  },
  {
    image: Slide_5,
    title: '5. Завершение обучения',
    description:
      'После успешного завершения обучения специалист закрывает группу внутренним протоколом c индивидуально присвоенным номером.',
  },
  {
    image: Slide_6_1,
    title: '6.1. Уведомление куратору',
    description:
      'В день окончания обучения аттестационные результаты всех групп и организаций автоматически формируются системой в протоколы и отправляются кураторам в pdf и xlsx формате.',
  },
  {
    image: Slide_6_2,
    title: '6.2. Уведомление куратору',
    description:
      'В день окончания обучения аттестационные результаты всех групп и организаций автоматически формируются системой в протоколы и отправляются кураторам в pdf и xlsx формате.',
  },
  {
    image: Slide_7_1,
    title: '7.1. Формирование протокола и подача данных в Минтруд',
    description:
      'Специалист загружает сформированный протокол в реестры Минтруда, где им присваиваются регистрационные номера для дальнейшего подписания и отправки документа.',
  },
  {
    image: Slide_7_2,
    title: '7.2. Формирование протокола и подача данных в Минтруд',
    description:
      'Специалист загружает сформированный протокол в реестры Минтруда, где им присваиваются регистрационные номера для дальнейшего подписания и отправки документа.',
  },
  {
    image: Slide_8,
    title: '8. Протокол',
    description: 'Итоговый результат.',
  },
];

export const adminSlidesData = createStore(AdminSlidesSrc);
