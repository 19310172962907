import React from 'react';
import styles from './Footer.module.css';
//* Графика
import Simax from '../../assets/simaxLogoWhite.png';
import Telega from '../../assets/teleg.png';
import Vk from '../../assets/vk.png';
//* Иконки
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
//* PDF документы
import Attestat from '../../assets/Pdf/attestat.pdf';
import InfoReestr from '../../assets/Pdf/infoReestr.pdf';
import Reestr from '../../assets/Pdf/reestr.pdf';
import StatusStatement from '../../assets/Pdf/выписка_организации_ОТ.pdf';
import SoftwareStatement from '../../assets/Pdf/Реестровая выписка Лицензия ЮЦ.pdf';

const Footer = () => {
  return (
    <div
      className={styles.root}
      id="footer">
      <div className={styles.footer}>
        <div className={styles.column_group}>
          <div className={styles.column}>
            <div className={styles.column_title}>КОМПАНИЯ</div>
            <a
              href="https://simax-it.ru/"
              target="blank">
              <div className={styles.column_item}>О нас</div>
            </a>
            <a
              href="https://simax-it.ru/staff/"
              target="blank">
              <div className={styles.column_item}>Наша команда</div>
            </a>
            <a
              href="https://simax-it.ru/company/news/"
              target="blank">
              <div className={styles.column_item}>Новости симакс</div>
            </a>
            <a
              href="https://simax-it.ru/company/documents/"
              target="blank">
              <div className={styles.column_item}>Документы</div>
            </a>
            <a
              href="https://simax-it.ru/company/vacancy/"
              target="blank">
              <div className={styles.column_item}>Вакансии</div>
            </a>
            <a
              href="https://simax-it.ru/company/for-partners/"
              target="blank">
              <div className={styles.column_item}>Для партнеров</div>
            </a>
            <a
              href="https://simax-it.ru/company/reviews/"
              target="blank">
              <div className={styles.column_item}>Отзывы клиентов</div>
            </a>
            <a
              href="https://simax-it.ru/company/requisites/"
              target="blank">
              <div className={styles.column_item}>Реквизиты</div>
            </a>
          </div>
          <div className={styles.column_doc}>
            <div className={styles.column_title}>
              ПРАВОУСТАНАВЛИВАЮЩИЕ ДОКУМЕНТЫ
            </div>
            <a
              href={Reestr}
              target="_blank"
              rel="noreferrer">
              <div className={styles.column_item_doc}>
                Свидетельство о государственной регистрации <br /> программы для
                ЭВМ
              </div>
            </a>
            <a
              href={InfoReestr}
              target="blank">
              <div className={styles.column_item_doc}>
                Сведения, содержащиеся в записи о <br />
                программном обеспечении, включенном
                <br /> в реестр российского ПО
              </div>
            </a>
            <a
              href={Attestat}
              target="blank">
              <div className={styles.column_item_doc}>
                Аттестат соответствия требованием по
                <br /> защите информации
              </div>
            </a>
            <a
              href={StatusStatement}
              target="blank">
              <div className={styles.column_item_doc}>
                Выписка из реестра организаций, <br />
                оказывающих услуги в области охраны труда
              </div>
            </a>
            <a
              href={SoftwareStatement}
              target="blank">
              <div className={styles.column_item_doc}>
                Выписка из реестра лицензий <br />
                по состоянию на «21» ноября 2023 г.
              </div>
            </a>
          </div>
          <div className={styles.column_contact}>
            <div className={styles.column_title}>НАШИ КОНТАКТЫ</div>
            <div className={styles.phone}>
              <PhoneIcon className={styles.icons} />
              +7 (499) 11-23-944 офис Москва
            </div>
            <div className={styles.phone}>
              <PhoneIcon className={styles.icons} />
              +7 (3452) 515-016 офис Тюмень
            </div>
            <div className={styles.time}>Пн. – Пт.: с 9:00 до 18:00</div>
            <div className={styles.place}>
              <PlaceIcon className={styles.icons} />
              г. Тюмень, ул. Республики, д. 164, корпус 2, офис 414
            </div>
            <div className={styles.email}>
              <AlternateEmailIcon className={styles.icons} />
              info@simax-it.ru
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.link}>
          <a
            href="https://t.me/simax_it"
            target="blank">
            <img
              className={styles.Telega}
              src={Telega}
              alt="Telega"
            />
          </a>
          <a
            href="https://vk.com/simax_it"
            target="blank">
            <img
              className={styles.Vk}
              src={Vk}
              alt="Vk"
            />
          </a>
        </div>
        <div className={styles.desc}>
          © 2024 ООО «СиМакс». Все права защищены. Вся информация представленная
          на сайте не является публичной офертой
        </div>
        <div>
          <img
            className={styles.logo}
            src={Simax}
            alt="Simax"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
