import React, { useEffect, useState } from 'react';
import styles from './Sidebar.module.css';
//* UI компоненты
import Tooltip from '@mui/material/Tooltip';
//* Графика
import Simax from '../../assets/logoSimaxSidebar.png';
import Educ from '../../assets/logoEducation.png';
import LMS from '../../assets/logoLMS.png';
import Passport from '../../assets/logoPassport.png';
import Kiosk from '../../assets/logoKiosk.png';
import MPK from '../../assets/logoMPK.png';

const Sidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);

  //* Юзэффект для отображения сайдбара
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerHeight = 1500;

      if (scrollPosition > triggerHeight) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //* Хэндлер на скрол по блокам
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    const offset = 10;
    if (element) {
      window.scrollTo({
        top: element.offsetTop + offset,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className={styles.root}>
      <div
        className={`${styles.sidebar} ${
          sidebarVisible ? styles.hideSidebar : styles.showSidebar
        }`}>
        <Tooltip
          title="Наверх"
          placement="right">
          <img
            src={Simax}
            alt=""
            className={styles.sidebar_item_home}
            onClick={() => scrollToElement('home')}
          />
        </Tooltip>
        <div className={styles.line}></div>
        <Tooltip
          title="Обучение / Learning Managment System"
          placement="right">
          <div className={styles.sidebar_item_double}>
            <img
              src={Educ}
              alt=""
              className={styles.sidebar_item}
              onClick={() => scrollToElement('education')}
            />
            <img
              src={LMS}
              alt=""
              className={styles.sidebar_item}
              onClick={() => scrollToElement('education')}
            />
          </div>
        </Tooltip>
        <div className={styles.line}></div>
        <Tooltip
          title="Паспорт обученности / Инфокиоск"
          placement="right">
          <div className={styles.sidebar_item_double}>
            <img
              src={Passport}
              alt=""
              className={styles.sidebar_item}
              onClick={() => scrollToElement('passport')}
            />
            <img
              src={Kiosk}
              alt=""
              className={styles.sidebar_item}
              onClick={() => scrollToElement('passport')}
            />
          </div>
        </Tooltip>
        <div className={styles.line}></div>
        <Tooltip
          title="Модуль Производственного Контроля"
          placement="right">
          <img
            src={MPK}
            alt=""
            className={styles.sidebar_item}
            onClick={() => scrollToElement('mpk')}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Sidebar;
