import { createStore } from 'effector';
import Slide_1 from '../assets/SlidesELearning/1.jpg';
import Slide_2 from '../assets/SlidesELearning/2.jpg';
import Slide_3 from '../assets/SlidesELearning/3.jpg';
import Slide_4_1 from '../assets/SlidesELearning/4_1.jpg';
import Slide_4_2 from '../assets/SlidesELearning/4_2.jpg';
import Slide_4_3 from '../assets/SlidesELearning/4_3.jpg';
import Slide_5_1 from '../assets/SlidesELearning/5_1.jpg';
import Slide_5_2 from '../assets/SlidesELearning/5_2.jpg';
import Slide_5_3 from '../assets/SlidesELearning/5_3.jpg';
import Slide_5_4 from '../assets/SlidesELearning/5_4.jpg';
import Slide_5_5 from '../assets/SlidesELearning/5_5.jpg';
import Slide_5_6 from '../assets/SlidesELearning/5_6.jpg';
import Slide_6 from '../assets/SlidesELearning/6.jpg';
import Slide_7 from '../assets/SlidesELearning/7.jpg';
import Slide_8 from '../assets/SlidesELearning/8.jpg';
import Slide_9 from '../assets/SlidesELearning/9.jpg';
import Slide_10 from '../assets/SlidesELearning/10.jpg';
import Slide_11 from '../assets/SlidesELearning/11.jpg';

const ELearningSlidesSrc = [
  {
    image: Slide_1,
    title: '1. Вход в личный кабинет.',
  },
  {
    image: Slide_2,
    title: '2. Просмотр активных, срочных и архивных курсов.',
  },
  {
    image: Slide_3,
    title: '3. Прохождение выбранного курса.',
  },
  {
    image: Slide_4_1,
    title:
      '4.1. Каждый курс обеспечен законодательной базой, доступной к изучению во время обучения.',
  },
  {
    image: Slide_4_2,
    title:
      '4.2. Каждый курс обеспечен законодательной базой, доступной к изучению во время обучения.',
  },
  {
    image: Slide_4_3,
    title:
      '4.3. Каждый курс обеспечен законодательной базой, доступной к изучению во время обучения.',
  },
  {
    image: Slide_5_1,
    title:
      '5.1. Для открытия доступа к аттестации обучающиеся изучают различные лекции, подробные инструкции и схемы в интерактивных уроках и видео от экспертов.',
  },
  {
    image: Slide_5_2,
    title:
      '5.2. Для открытия доступа к аттестации обучающиеся изучают различные лекции, подробные инструкции и схемы в интерактивных уроках и видео от экспертов.',
  },
  {
    image: Slide_5_3,
    title:
      '5.3. Для открытия доступа к аттестации обучающиеся изучают различные лекции, подробные инструкции и схемы в интерактивных уроках и видео от экспертов.',
  },
  {
    image: Slide_5_4,
    title:
      '5.4. Для открытия доступа к аттестации обучающиеся изучают различные лекции, подробные инструкции и схемы в интерактивных уроках и видео от экспертов.',
  },
  {
    image: Slide_5_5,
    title:
      '5.5. Для открытия доступа к аттестации обучающиеся изучают различные лекции, подробные инструкции и схемы в интерактивных уроках и видео от экспертов.',
  },
  {
    image: Slide_5_6,
    title:
      '5.6. Для открытия доступа к аттестации обучающиеся изучают различные лекции, подробные инструкции и схемы в интерактивных уроках и видео от экспертов.',
  },
  {
    image: Slide_6,
    title:
      '6. Постоянная тех.поддержка слушателей и администраторов системы силами своей IT-команды.',
  },
  {
    image: Slide_7,
    title: '7. Заполнение анкеты обратной связи для улучшения качества курсов.',
  },
  {
    image: Slide_8,
    title:
      '8. Открытие доступа к аттестации после прохождения не менее 80% материалов.',
  },
  {
    image: Slide_9,
    title: '9. Аттестация.',
  },
  {
    image: Slide_10,
    title: '10. Успешное завершение обучения.',
  },
  {
    image: Slide_11,
    title:
      '11. Получение соответствующих документов, удостоверяющих пройденное обучение.',
  },
];

export const eLearningSlidesData = createStore(ELearningSlidesSrc);
