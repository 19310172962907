import React, { useEffect, useState } from 'react';
import styles from './HomeUp.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './MenuAnimation.css';
//* UI компоненты
import Tooltip from '@mui/material/Tooltip';
//* Графика
import Simax from '../../assets/logoSimaxSidebar.png';
//* Иконки
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';

const HomeUp = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [menuVisible, setIsMenuVisible] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);

  //* Хэндлер для открытия меню
  const handleMenu = () => {
    setIsMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerHeight = 40;

      if (scrollPosition > triggerHeight) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //* Хэндлер для скрола по блокам
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    const offset = 10;
    if (element) {
      window.scrollTo({
        top: element.offsetTop + offset,
        behavior: 'smooth',
      });
    }
  };

  //* Хэндлеры для анимации кнопки меню
  const handleMenuClick = () => {
    setAnimationActive(true);
  };

  const handleAnimationEnd = () => {
    setAnimationActive(false);
  };

  return (
    <div className={styles.root}>
      <div
        className={`${styles.sidebar} ${
          sidebarVisible ? styles.hideSidebar : styles.showSidebar
        }`}>
        <Tooltip
          title="Меню"
          placement="right">
          <img
            src={Simax}
            alt=""
            className={`${styles.sidebar_item_home} ${
              animationActive ? styles.sidebar_item_home_rotate : ''
            }`}
            onClick={() => {
              handleMenu();
              handleMenuClick();
            }}
            onAnimationEnd={handleAnimationEnd}
          />
        </Tooltip>

        <TransitionGroup>
          {menuVisible && (
            <CSSTransition
              classNames="menu"
              timeout={0}>
              <div className={styles.menu}>
                <div
                  className={styles.menu_item}
                  onClick={() => scrollToElement('education')}>
                  <SchoolIcon className={styles.icons} />
                  Обучение
                </div>
                <div
                  className={styles.menu_item}
                  onClick={() => scrollToElement('passport')}>
                  <PersonIcon className={styles.icons} />
                  Паспорт / Инфокиоск
                </div>
                <div
                  className={styles.menu_item}
                  onClick={() => scrollToElement('mpk')}>
                  <SettingsIcon className={styles.icons} />
                  МПК
                </div>
                <div className={styles.line}></div>
                <div
                  className={styles.menu_item}
                  onClick={() => scrollToElement('footer')}>
                  <DescriptionIcon className={styles.icons} />
                  Документы
                </div>
                <div
                  className={styles.menu_item}
                  onClick={() => scrollToElement('home')}>
                  <KeyboardCapslockIcon className={styles.icons} />
                  <strong>Наверх</strong>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default HomeUp;
