import React, { useEffect, useState } from 'react';
import styles from './HeaderBanner.module.css';
//* Графика
import Banner from '../../assets/banner.png';
import BannerMobile from '../../assets/bannerMobile.png';

const HeaderBanner = () => {
  const [isMobile, setIsMobile] = useState(
    'https://imageup.ru/img245/4715361/banner.jpg'
  );

  //* Юзэффект для изменения главно баннера (Десктоп/Мобильные устройства)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setIsMobile('https://imageup.ru/img31/4715806/bannermobilev4.jpg');
      } else {
        setIsMobile('https://imageup.ru/img245/4715361/banner.jpg');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={styles.root}
      id="home">
      <div className={styles.banner_wrapper}>
        <img
          src={isMobile}
          alt="banner_wrapper"
        />
      </div>
    </div>
  );
};

export default HeaderBanner;
