import { createStore } from 'effector';
import KarevaV from '../assets/team/ВалерияК.png';
import BitykovD from '../assets/team/ДмитрийБ.png';
import PelihovkiyD from '../assets/team/ДмитрийП.png';
import GolenY from '../assets/team/ЮлияГ.png';
import PurilovaI from '../assets/team/ИринаП.png';
import VeligockihR from '../assets/team/РоманВ.png';
import ShadrinE from '../assets/team/ЕвгенийШ.png';
import BlagodatskihV from '../assets/team/ВиталийБ.png';
import IsupovA from '../assets/team/АндрейИ.png';
import ShkrebenokV from '../assets/team/ВасилийШ.png';
import MelihovA from '../assets/team/АлексейМ.png';
import GakA from '../assets/team/АнастасияГ.png';
import BakeevR from '../assets/team/РустамБ.png';
import FateevaS from '../assets/team/СветланаФ.png';
import KorzhavinaL from '../assets/team/ЛилияК.png';
import ZarudnyaA from '../assets/team/АринаЗ.png';
import IvaschenkoA from '../assets/team/АннаИ.png';
import BitykovaE from '../assets/team/ЕкатеринаБ.png';
import MaidanovaE from '../assets/team/ЕленаМ.png';

const ourTeamSrc = [
  {
    id: 1,
    src: KarevaV,
    name: 'Карева Валерия',
    post: 'Генеральный директор',
  },
  {
    id: 2,
    src: BitykovD,
    name: 'Битюков Дмитрий',
    post: 'Коммерческий директор',
  },
  {
    id: 2,
    src: PelihovkiyD,
    name: 'Пелиховский Дмитрий',
    post: 'Руководитель отдела разработки',
  },
  {
    id: 2,
    src: GolenY,
    name: 'Голень Юлия',
    post: 'Директор по продажам',
  },
  {
    id: 2,
    src: PurilovaI,
    name: 'Путилова Ирина',
    post: 'Руководитель отдела обучения',
  },
  {
    id: 2,
    src: VeligockihR,
    name: 'Велигоцкий Роман',
    post: 'Техлид',
  },
  {
    id: 2,
    src: ShadrinE,
    name: 'Шадрин Евгений',
    post: 'Инженер по тестированию',
  },
  {
    id: 2,
    src: BlagodatskihV,
    name: 'Благодатских Виталий',
    post: 'SEO специалист',
  },
  {
    id: 2,
    src: IsupovA,
    name: 'Исупов Андрей',
    post: 'Фронтенд-разработчик',
  },
  {
    id: 2,
    src: ShkrebenokV,
    name: 'Шкребенок Василий',
    post: 'Фронтенд-разработчик',
  },
  {
    id: 2,
    src: MelihovA,
    name: 'Мелехов Алексей',
    post: 'Ruby-разработчик',
  },
  {
    id: 2,
    src: GakA,
    name: 'Гак Анастасия',
    post: 'Методист',
  },
  {
    id: 2,
    src: BakeevR,
    name: 'Бакеев Рустам',
    post: 'Специалист по обучению',
  },
  {
    id: 2,
    src: FateevaS,
    name: 'Фатеева Светлана',
    post: 'Cпециалист по обучению',
  },
  {
    id: 2,
    src: KorzhavinaL,
    name: 'Коржавина Лилия',
    post: 'Специалист отдела обучения',
  },
  {
    id: 2,
    src: ZarudnyaA,
    name: 'Арина Зарудняя',
    post: 'Специалист отдела обучения, методист',
  },
  {
    id: 2,
    src: IvaschenkoA,
    name: 'Анна Иващенко',
    post: 'Специалист отдела обучения, методист',
  },
  {
    id: 2,
    src: MaidanovaE,
    name: 'Елена Майданова',
    post: 'Специалист отдела обучения',
  },
  {
    id: 2,
    src: BitykovaE,
    name: 'Екатерина Битюкова',
    post: 'Специалист отдела обучения',
  },
];

export const ourTeamSrcData = createStore(ourTeamSrc);
