import React, { useEffect, useState } from 'react';
import styles from './Timeline.module.css';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
//* Компоненты
import Logo from '../../ui/Logo/Logo';

const Timeline = () => {
  const [marginTop, setMarginTop] = useState('5px');
  const [marginLeft, setMarginLeft] = useState('-25px');

  //* Юзэффект для отступов поинтов с логотипом симакс на таймлайне
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1170) {
        setMarginTop('0');
        setMarginLeft('-5px');
      } else {
        setMarginTop('5px');
        setMarginLeft('-25px');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //* Хэндлер для скрола
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    const offset = 10;
    if (element) {
      window.scrollTo({
        top: element.offsetTop + offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.root}>
      <VerticalTimeline
        className={styles.timeline}
        lineColor="black"
        layout={'2-columns'}>
        <VerticalTimelineElement
          className="box"
          contentStyle={{
            backgroundColor: '#f6f7f8',
            color: 'rgb(0, 0, 0)',
            boxShadow: 'none',
            borderRadius: '15px',
          }}
          date="Обучение"
          dateStyle={{ fontSize: '30px' }}
          iconStyle={{
            width: '50px',
            height: '50px',
            marginTop: marginTop,
            marginLeft: marginLeft,
            background: '#ffd800',
          }}
          icon={<Logo />}
          contentArrowStyle={{ borderRight: '7px solid #ffd800' }}>
          <div
            className={styles.item_wrapper}
            onClick={() => scrollToElement('education')}>
            <div className={styles.left_wrapper}>
              <div className={styles.count}>01</div>
            </div>
            <div className={styles.right_wrapper}>
              <div className={styles.desc}>
                Группа компаний «Симакс» реализует 1000+ образовательных
                программ, сотрудничает с более 100 предприятий и аттестует более
                100 тысяч слушателей ежегодно. Качество обучения подтверждается
                документами государственного образца.
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="box"
          contentStyle={{
            backgroundColor: '#f6f7f8',
            color: 'rgb(0, 0, 0)',
            boxShadow: 'none',
            borderRadius: '15px',
          }}
          date="Learning Managment System"
          contentArrowStyle={{ borderRight: '7px solid #11a53e' }}
          iconStyle={{
            background: '#11a53e',
            width: '50px',
            height: '50px',
            marginTop: marginTop,
            marginLeft: marginLeft,
          }}
          icon={<Logo />}>
          <div
            className={styles.item_wrapper}
            onClick={() => scrollToElement('education')}>
            <div className={styles.left_wrapper}>
              <div className={styles.count}>02</div>
            </div>
            <div className={styles.right_wrapper}>
              <div className={styles.desc}>
                Система дистанционного обучения сопровождается собственной
                командой разработчиков с 2012 года. Продукт учитывает актуальные
                требования законодательства и современных технологий.
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="box"
          contentStyle={{
            backgroundColor: '#f6f7f8',
            color: 'rgb(0, 0, 0)',
            boxShadow: 'none',
            borderRadius: '15px',
          }}
          date="Паспорт обученности"
          contentArrowStyle={{ borderRight: '7px solid #8d4ccb' }}
          iconStyle={{
            background: '#8d4ccb',
            width: '50px',
            height: '50px',
            marginTop: marginTop,
            marginLeft: marginLeft,
          }}
          icon={<Logo />}>
          <div
            className={styles.item_wrapper}
            onClick={() => scrollToElement('passport')}>
            <div className={styles.left_wrapper}>
              <div className={styles.count}>03</div>
            </div>
            <div className={styles.right_wrapper}>
              <div className={styles.desc}>
                Электронный паспорт обученности обеспечивает понятный и
                прозрачный процесс учета, отслеживания и регистрации
                образовательных результатов для обучающихся и учебных центров.
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="box"
          contentStyle={{
            backgroundColor: '#f6f7f8',
            color: 'rgb(0, 0, 0)',
            boxShadow: 'none',
            borderRadius: '15px',
          }}
          date="Инфокиоск"
          contentArrowStyle={{ borderRight: '7px solid #46a5c5' }}
          iconStyle={{
            background: '#46a5c5',
            width: '50px',
            height: '50px',
            marginTop: marginTop,
            marginLeft: marginLeft,
          }}
          icon={<Logo />}>
          <div
            className={styles.item_wrapper}
            onClick={() => scrollToElement('passport')}>
            <div className={styles.left_wrapper}>
              <div className={styles.count}>04</div>
            </div>
            <div className={styles.right_wrapper}>
              <div className={styles.desc}>
                Разработанный компанией «Симакс» инфокиоск позволяет размещать и
                демонстрировать учебные и оперативные материалы. Карточка
                обученного предоставляет доступ к результатам обучения
                и доступным курсам.
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="box"
          contentStyle={{
            backgroundColor: '#f6f7f8',
            color: 'rgb(0, 0, 0)',
            boxShadow: 'none',
            borderRadius: '15px',
          }}
          date="Модуль Производственного Контроля"
          contentArrowStyle={{ borderRight: '7px solid #df1717' }}
          iconStyle={{
            background: '#df1717',
            width: '50px',
            height: '50px',
            marginTop: marginTop,
            marginLeft: marginLeft,
          }}
          icon={<Logo />}>
          <div
            className={styles.item_wrapper}
            onClick={() => scrollToElement('mpk')}>
            <div className={styles.left_wrapper}>
              <div className={styles.count}>05</div>
            </div>
            <div className={styles.right_wrapper}>
              <div className={styles.desc}>
                Модуль производственного контроля разработан для фиксации
                нарушений, проведения индивидуальных и коллегиальных проверок и
                исключения потенциальных несчастных случаев на различных
                производствах.
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="box"
          contentStyle={{
            backgroundColor: '#f6f7f8',
            color: 'rgb(0, 0, 0)',
            boxShadow: 'none',
            borderRadius: '15px',
          }}
          date="Правоустанавливающие документы"
          contentArrowStyle={{ borderRight: '7px solid #000' }}
          iconStyle={{
            background: '#000',
            width: '50px',
            height: '50px',
            marginTop: marginTop,
            marginLeft: marginLeft,
          }}
          icon={<Logo />}>
          <div
            className={styles.item_wrapper}
            onClick={() => scrollToElement('footer')}>
            <div className={styles.left_wrapper}>
              <div className={styles.count}>06</div>
            </div>
            <div className={styles.right_wrapper}>
              <div className={styles.desc}>
                Правоустанавливающие документы группы компаний "СиМакс".
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Timeline;
