import React from 'react';
import Alert from '@mui/material/Alert';
import styles from './AlertMobile.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './AlertMobile.css';

const AlertMobile = ({ showAlert, setShowAlert }) => {
  const handleClose = () => {
    setShowAlert(false);
  };

  if (!showAlert) {
    return null;
  }

  return (
    <div className={styles.root}>
      <TransitionGroup>
        <CSSTransition
          classNames="alertMobile"
          timeout={300}>
          <Alert
            severity="info"
            onClose={handleClose}
            className={styles.alert}>
            Поверните устройство горизонтально
          </Alert>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default AlertMobile;
