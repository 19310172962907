import React from 'react';
import styles from './StatisticBlock.module.css';
import Slide1 from '../../assets/SlidesStatistic/112.png';
import Round from '../../assets/SlidesStatistic/round.png';
import PeopleIcon from '@mui/icons-material/People';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
const StatisticBlock = () => {
  return (
    <div className={styles.root}>
      <div className={styles.statistic_wrapper}>
        <div className={styles.left_wrapper}>
          <div className={styles.left_wrapper_title}>Статистика</div>
          <img
            src={Slide1}
            alt=""
          />
        </div>
        <div className={styles.right_wrapper}>
          <div className={styles.first}>
            <PeopleIcon className={styles.people_icon} />
            <div className={styles.first_item_title}>
              Информация по 2023 году
            </div>
            <div className={styles.first_item_group}>
              <div className={styles.first_item}>
                <div className={styles.first_item_icon}>1/</div>
                Количество обученных с использованием СДО более 100000 человек.
              </div>
              <div className={styles.first_item}>
                <div className={styles.first_item_icon}>2/</div>
                Количество обученных очно более 10000 человек в 30 локациях по
                всей стране.
              </div>
            </div>
            <div className={styles.first_item_outside}>
              <div className={styles.first_item_icon}>3/</div>В процессе
              обучения задействовано более 50 человек, из них 25 человек штатных
              тренеров-преподавателей и 13 человек – специалисты
              учебно-методического отдела
            </div>
          </div>
          <div className={styles.second_title}>
            В югорском учебном центре разработаны и реализуются более 600
            учебных программ, в числе которых:
          </div>
          <div className={styles.second}>
            <div className={styles.second_block_left}>
              <div className={styles.second_block_left_item}>
                <div className={styles.desc}>
                  Дополнительное профессиональное образование:
                  <br />
                  <br />
                </div>
              </div>
              <div className={styles.second_block_left_item}>
                <div className={styles.count}>+331</div>
                <div className={styles.desc}>Обучение рабочим профессиям</div>
              </div>
              <div className={styles.second_block_left_item}>
                <div className={styles.count}>+45</div>
                <div className={styles.desc}>
                  Программы обучения по охране труда
                </div>
              </div>
              <div className={styles.second_block_left_item}>
                <div className={styles.count}>+46</div>
                <div className={styles.desc}>Курсы целевого назначения</div>
              </div>
            </div>
            <div className={styles.second_block_right}>
              <div className={styles.second_block_right_item}>
                <div className={styles.second_block_right_group}>
                  <div className={styles.desc2}>Профессиональное обучение:</div>
                </div>
              </div>
              <div className={styles.second_block_right_item}>
                <div className={styles.second_block_right_group}>
                  <div className={styles.count2}>+162</div>
                  <div className={styles.desc2}>
                    Профессиональная переподготовка
                  </div>
                </div>
              </div>
              <div className={styles.second_block_right_item}>
                <div className={styles.second_block_right_group}>
                  <div className={styles.count2}>+46</div>
                  <div className={styles.desc2}>Повышение квалификации</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.third}>
            <div className={styles.third_block_left}>
              <img
                src={Round}
                alt=""
              />
            </div>
            <div className={styles.third_block_right}>
              Также Югорский учебный центр в полном объеме закрывает потребность
              ГПН в обучении по корпоративным программам на 6 локациях.
              <FormatQuoteIcon className={styles.formatQuoteIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
