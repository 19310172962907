import React, { useState } from 'react';
import styles from './ModalEducation.module.css';
import { useStore } from 'effector-react';
import { educationSlidesSrcData } from '../../store/EducationSlides';
import CloseIcon from '@mui/icons-material/Close';

const ModalEducation = ({ isOpen, onClose }) => {
  const slides = useStore(educationSlidesSrcData);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const showPreviousSlide = () => {
    setCurrentSlideIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  const showNextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const updateSlideCounter = () => {
    return `${currentSlideIndex + 1}/${slides.length} слайдов`;
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <img
            className={styles.pic}
            alt={slides[currentSlideIndex].title}
            src={slides[currentSlideIndex].image}
          />
          <div className={styles.counter}>{updateSlideCounter()}</div>
          <div className={styles.arrow_group}>
            <div
              className={styles.arrow}
              onClick={showPreviousSlide}>
              Предыдущий
            </div>

            <div
              className={styles.arrow}
              onClick={showNextSlide}>
              Следующий
            </div>
          </div>
          <div
            className={styles.close}
            onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.title}>{slides[currentSlideIndex].title}</div>
        <div className={styles.desc}>
          {slides[currentSlideIndex].description}
        </div>
      </div>
    </div>
  );
};

export default ModalEducation;
